.Line {
    display: flex;
    height: 100%;
    align-items: center;
    gap:var(--gap)
}

.Line[justify="between"] {
    justify-content: space-between;
}

.Line[justify="center"] {
    justify-content: center;
}

.Line[alignborders="true"] > :first-child {
    text-align: left;
}

.Line[alignborders="true"] > :last-child {
    text-align: right;
}