:root {
    --font-size: 16px;
    --font-size-nav: 1.05rem;
    --font-size-m: 1.2rem;
    --font-size-l: 1.5rem;
    --font-size-xl: 2rem;

    --font-family: 'Ubuntu', sans-serif;
    --background: #f1f1f1;
    --background-white: #fff;
    --primary-color: #4FBE37;
    --primary-color-dark: #46ac3a;
    --accent-color: #54b948;

    --font-color:#1d1d1d;
    --font-color-alter:#fff;

    --error-background: #F7E7E7;
    --error-color: #BF3728;

    --border: #e1e1e1;

    --gap: 1rem;
    --gap-xl: 3rem;

    --shadow-front: 0px 0px 8px #e1e1e1;
}