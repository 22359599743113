html, body, #root, .App {
    height: 100%;
}

html {
    font-size: var(--font-size);
}

body {
    margin: 0;
    background-color: var(--background);
    font-size: var(--font-size);
}

body, input, textarea, pre {
    font-family: var(--font-family);
    color: var(--font-color);
}
h1, h2, h3, h4, h5 {
    margin: 0;
    font-weight: 100;
    color: var(--font-color);
}

img {
    width: 4rem;
}

img.small {
    height: 3rem;
}

img.full {
    width: 100%;
}

a {
    color: inherit;
    height: inherit;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

a.active {
    font-weight: bold;
}

table {
    border-spacing: 0px;
    width:100%;
  }

th, td {
  padding: var(--gap);
}

th {
    color :var(--primary-color);
    border-bottom: 2px solid var(--font-color);
}

th,td {
    text-align: right;
}

table td:nth-child(2),table th:nth-child(2) {
    text-align: left;
}
table td:nth-child(1),table th:nth-child(1) {
    text-align: left;
}





@media screen and (max-width: 600px) {

}